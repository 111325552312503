<template>
  <div class="div-calendar-rd">
    <b-form-input
      v-if="showInput"
      :value="getFormattedDate()"
      class="invision-input"
      :class="inputClass"
      @click="open"
      :disabled="isBusy"
      :state="state"
    />
    <b-calendar
      v-if="showCalendar"
      ref="calendar"
      class="w-100 calendar-rd"
      :class="position"
      weekdayHeaderFormat="narrow"
      :date-info-fn="dateClass"
      locale="pt-BR"
      :hide-header="hideHeader"
      label-help=""
      no-highlight-today
      @input="$refs['calendar'].blur()"
      @selected="dateSelected"
    >
      <b-row v-if="showButton" align-h="between" class="pt-4">
        <b-col>
          <Button
            text="Cancelar"
            variant="primary-light"
            type="text"
            size="sm"
            :loading="isBusy"
            @click="close()"
          />
        </b-col>
        <b-col class="text-right">
          <Button
            text="OK"
            variant="primary-light"
            type="text"
            size="sm"
            :loading="isBusy"
            @click="$emit('confirm-range-date')"
          />
        </b-col>
      </b-row>
    </b-calendar>
    <div v-if="showCalendar" class="outside" @click="close()"></div>
  </div>
</template>

<script>
import moment from 'moment';
import Button from '@/components/Utils/Button';

export default {
  components: {
    Button,
  },

  data: () => ({
    init: null,
    end: null,
    showCalendar: false,
  }),

  props: {
    position: {
      type: String,
      default: '',
      required: false,
    },
    initDate: {
      type: Object,
      default: null,
    },
    endDate: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
    },
    hideHeader: {
      type: Boolean,
      default: true,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showInput: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.init = this.initDate;
    this.end = this.endDate;
  },

  watch: {
    initDate(v) {
      this.init = v;
    },
    endDate(v) {
      this.end = v;
    },
  },

  methods: {
    open() {
      this.showCalendar = true;
    },

    close() {
      this.showCalendar = false;
    },

    dateClass(ymd, date) {
      const day = moment(date);
      if (day.isBetween(this.init, this.end, undefined, '[]')) {
        return 'active-date';
      }
      return '';
    },
    getFormattedDate() {
      return `${
        this.initDate ? this.initDate.format('DD/MM/YY') : '-'} a ${this.endDate ? this.endDate.format('DD/MM/YY') : '-'
      }`;
    },
    dateSelected(ymd, date) {
      const newDate = moment(date);
      if (!this.init) {
        this.init = newDate.clone().startOf('day');
      } else if (!this.end) {
        if (newDate.isBefore(this.init)) {
          this.init = newDate.clone().startOf('day');
        } else {
          this.end = newDate.clone().endOf('day');
          this.$emit('selected', this.init, this.end);
          if (this.showInput) this.close();
        }
      } else {
        this.init = newDate.clone().startOf('day');
        this.end = null;
      }
    },
  },

};
</script>

<style>
.button--green {
  color: #00daae;
  border-style: solid;
  border-radius: 0.6rem;
  border-width: 0.15rem;
  padding: 0.4rem;
}
.button--green.unselected {
  opacity: 0.3;
}
.button--green.selected {
  background-color: rgba(0, 218, 174, 0.25) !important;
}
.button--green:hover {
  background-color: #00daae !important;
  color: white !important;
}
.button--green .icon {
  fill: #00daae;
  margin: 0;
}

.div-calendar-rd {
  position: absolute;
  z-index: 9;
  background-color: #FFFFFF;
  border-radius: 5px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.calendar-rd {
  position: absolute;
  background-color: #FFFFFF;
  z-index: 9;
  margin: 1rem;
}

.b-calendar-inner {
  background-color: #FFFFFF !important;
  border-radius: 5px !important;
  padding: 0.5rem !important;
  filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
}

.calendar-rd.top-left{
  left: -30px;
  bottom: 50px;
}

.calendar-rd .form-control{
  border-width: 0;
}
.calendar-rd .active {
  background-color: rgba(0, 218, 174, 0.25) !important;
  border-radius: 0px !important;
  color: #000 !important;
}

.active-date {
  background-color:  rgba(0, 218, 174, 0.25);
}

.calendar-rd .btn-outline-secondary {
  color: #00daae;
}
.calendar-rd .b-calendar-grid-caption.font-weight-bold {
  color: #00daae;
  font-weight: 400 !important;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
}
</style>
