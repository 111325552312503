<template>
  <div>
    <GenericTable
      ref="lista"
      name="troca"
      title="trocas de detergente enzimático"
      add-route="loteDetergenteAdicionar"
      edit-route=""
      :cols="[
        'Equipamento',
        'Unidade',
        'Data/Hora',
        'Lote do Detergente',
        'Colaborador',
      ]"
      :cols-name="[
        'equipamento',
        'unidade',
        'data',
        'lote_detergente',
        'colaborador',
      ]"
      :cols-map="
        (i) => [
          i.equipamento,
          i.unidade,
          i.data_troca,
          i.lote_detergente,
          i.colaborador,
        ]
      "
      route="loteDetergente"
      :permissionsToWrite="['rw_material', 'rw_detergente']"
      :nofilters="false"
      :filters="filters"
      :hasPagination="true"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Equipamento</label>
              <GenericSelect
                name="equipamento"
                labelKey="nome"
                v-model="filters.id_equipamento"
                :customFilters="equipamentoEsterilizacaoFilters"
                route="equipamento"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <GenericSelect
                name="unidade"
                labelKey="nome"
                route="unidade"
                v-model="filters.id_unidade"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome do Colaborador</label>
              <b-form-input
                v-model="filters.nome_colaborador"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Lote do Detergente</label>
              <b-form-input
                v-model="filters.lote_detergente"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Período</label>
              <RangeDatePicker
                :initDate="filters.initDate"
                :endDate="filters.endDate"
                @selected="selectedDateInDatePicker"
                position="top-left"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import GenericSelect from '@/components/Form/GenericSelect';
import RangeDatePicker from '@/components/Form/RangeDatePicker';

export default {
  components: {
    GenericSelect,
    RangeDatePicker,
  },
  computed: {
    ...mapState({
      currentUnidade: (state) => state.currentUnidade,
    }),
    equipamentoEsterilizacaoFilters() {
      let idRetorno = 0;
      if (this.currentUnidade.id_unidade) {
        idRetorno = this.currentUnidade.id_unidade;
      }

      return {
        id_unidade: idRetorno,
      };
    },
  },
  data() {
    return {
      filters: {
        id_equipamento: null,
        id_unidade: null,
        initDate: moment().subtract(7, 'days'),
        endDate: moment(),
        lote_detergente: '',
        nome_colaborador: '',
      },
    };
  },
  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.filters.initDate = initDate;
      this.filters.endDate = endDate;
    },
  },
};
</script>
